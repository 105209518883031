import { defineComponent, computed, watch, getCurrentInstance, ref } from '@vue/composition-api';
import { useGetFooterContentQuery } from '@/generated-types/graphql.types';
import { useStoryblokBridge } from '@/shared/composables/useStoryblokBridge';
export default defineComponent({
    setup() {
        const instance = getCurrentInstance();
        const { result, refetch } = useGetFooterContentQuery({ enabled: true });
        const storyBase = ref(null);
        const story = computed({
            get: () => storyBase.value,
            set: (value) => (storyBase.value = value)
        });
        watch(() => result.value, () => {
            if (result.value) {
                storyBase.value = JSON.parse(result.value.get_footer_cms_content.story).story;
            }
        }, { immediate: true });
        const footer = computed(() => {
            return story.value?.content.body.find((item) => item.component === 'footer');
        });
        /**
         * Watch for locale change and refetch the home page content
         */
        watch(() => globalThis.$i18n.locale, () => {
            refetch();
        });
        if (window.location.search.includes('_storyblok')) {
            // load the bridge only inside of Storyblok Editor
            useStoryblokBridge(story);
            instance.$on('storyPublished', refetch);
        }
        return { footer };
    },
    mounted() {
        window.addEventListener('load', () => {
            // run after everything is in-place in the DOM
            // Programmatically Add target="_blank" and ref="noreferrer" to the navigiation block items with
            // `main-footer-privacy` class as those items are rendered by the Storyblok and there is no observable way
            // to add custom properties to the HTML elements in the Storyblok dashboard
            this.$el
                .querySelectorAll('div.main-footer-privacy a')
                .forEach(function (el) {
                el.target = '_blank';
                el.rel = 'noreferrer';
                el.rel = 'noopener';
            });
            this.$el
                .querySelectorAll('.social-media > .storyblok-nav-item > a')
                .forEach(function (el) {
                el.target = '_blank';
                el.rel = 'noreferrer';
                el.rel = 'noopener';
            });
        });
    }
});
